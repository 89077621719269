import { useMemo } from 'react'

import { getCurrentDeck } from '../utils/project'
import useRendererContext from './useRendererContext'

const useSlidePosition = () => {
  const { project, currentDeckId, currentSlideId } = useRendererContext()

  return useMemo(() => {
    const currentDeck = getCurrentDeck(project, currentDeckId)
    const currentIndex = currentDeck?.slides?.findIndex(s => s.id === currentSlideId)
    const hasPrev = currentIndex > 0
    const hasNext = currentIndex < (currentDeck?.slides?.length - 1)

    return {
      hasNext,
      currentIndex,
      hasPrev
    }
  }, [currentDeckId, currentSlideId])
}

export default useSlidePosition
