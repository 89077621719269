import { EventType } from '@pidk/api/src/types/api'
import type { Project } from '@pidk/compose/src/types/compose'
import { getDeckStartedFromStorage, removeDeckStartedFromStorage } from '@pidk/renderer/src/utils/event'
import { getIsCheckingOut, setIsCheckingOut } from '@pidk/web/src/utils/checkout'
import { useState, useRef, useEffect } from 'react'
import { useUpdateEffect, useFullscreen, useToggle } from 'react-use'
import styled from 'styled-components'

import useRendererContext from '../../hooks/useRendererContext'
import useSlidePosition from '../../hooks/useSlidePosition'
// import { getDeckHasFinished, setDeckFinished } from '../../utils/finishedDeckStore'
import { getCurrentDeck, getCurrentSlide } from '../../utils/project'
import { getSlideTypeComponent } from '../../utils/slide'
import ModalStart from '../Overview/ModalStart'
import Nav from './../Nav/Nav'

const Placeholder = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-family: ${props => props.theme.fonts.fontFamily.heading};
`

interface SlideProps {
  project: Project
  currentDeckId: string
  currentSlideId: string
  isEditable: boolean
  selectedAreaId?: string
  onAreaClick: (id: string) => void
}

const Slide = ({
  project,
  currentDeckId,
  currentSlideId,
  isEditable,
  onAreaClick,
  selectedAreaId
}: SlideProps) => {
  const deckRef = useRef(null)
  const currentDeck = getCurrentDeck(project, currentDeckId)
  const currentSlide = getCurrentSlide(project, currentDeckId, currentSlideId)
  const { hasNext, currentIndex } = useSlidePosition()
  const { onScopedEventCreate, rendererState, setRendererState, isPreview } = useRendererContext()
  const [showModalStart, setShowModalStart] = useState<boolean>(!rendererState.hasAskedForStartLesson && !isPreview)
  const [isFullScreen, setIsFullScreen] = useToggle(false)

  useFullscreen(deckRef, isFullScreen, { onClose: () => setIsFullScreen(false) })

  const hideModalStart = () => {
    setShowModalStart(false)
  }

  // If a lesson is started setIsFullScreen fullscreen modus, also check it everytime navigate between slides
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      if (rendererState.hasStarted) {
        setIsFullScreen(true)
      }
    }
  }, [rendererState.hasStarted, currentSlideId])

  // TODO: Force reset when switching between decks, could probably be refactored
  useEffect(() => {
    setShowModalStart(true)
    setRendererState(prevState => ({
      ...prevState,
      hasStarted: false,
      hasAskedForStartLesson: false
    }))
  }, [currentDeck.id])

  const closeSession = async () => {
    const startedDeck = getDeckStartedFromStorage()

    if (startedDeck.deckId !== currentDeckId) {
      return
    }

    setRendererState(prevState => ({
      ...prevState,
      checkoutCallback: undefined,
      hasStarted: false
    }))

    removeDeckStartedFromStorage()
    setIsCheckingOut(false)

    await onScopedEventCreate({
      type: EventType.DECK_FINISH,
      deckId: startedDeck.deckId,
      meta: { interactive: rendererState.isConnected }
    })
  }

  useUpdateEffect(() => {
    (async () => {
      if (isEditable) {
        return
      }

      if (hasNext) {
        return
      }

      if (!rendererState.hasStarted) {
        return
      }

      /*
        * Ugly hack to make sure the last slide is finished rendering before closing the session.
        * But it seems to be working.
        * It sometimes happens that the isCheckingOut is set to false before the last slide is finished rendering and thus before it's set to true.
      */
      setTimeout(async () => {
        const isCheckingOut = getIsCheckingOut()

        if (isCheckingOut) {
          setRendererState(prevState => ({
            ...prevState,
            checkoutCallback: closeSession
          }))
        } else {
          await closeSession()
        }
      }, 1000)
    })()
  }, [currentIndex, hasNext])

  if (!currentSlide) {
    return (
      <>
        <Placeholder>
          Screen not found
        </Placeholder>
        <Nav />
      </>
    )
  }

  const SlideComponent = getSlideTypeComponent(currentSlide.type)

  return (
    <div
      ref={deckRef}
      style={{ background: 'inherit' }}
    >
      {!isPreview && showModalStart && !project.disableModalStart && (
        <ModalStart
          onHide={hideModalStart}
          deckId={currentDeckId}
        />
      )}
      <SlideComponent
        slide={currentSlide}
        deck={currentDeck}
        isEditable={isEditable}
        onAreaClick={onAreaClick}
        selectedAreaId={selectedAreaId}
        logo={project.overview.logo}
      />
      <Nav />
    </div>
  )
}

export default Slide
