import { getProjectThemeColor } from '@pidk/renderer/src/utils/project'
import { useState } from 'react'
import styled from 'styled-components'

import useRendererContext from '../../hooks/useRendererContext'
import Image from '../Blocks/Image'
import { Logo } from '../Slide/Slide.styled'
import type { IProjectLoginFormFields } from './ProjectLoginForm'
import ProjectLoginForm from './ProjectLoginForm'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-family: ${({ theme }) => theme.fonts.fontFamily.body};
  background-color: ${({ theme }) => getProjectThemeColor(theme.colors, theme.login.backgroundColor)};
`

interface IProjectLoginFormState {
  error?: unknown
  isSubmitting: boolean
}

const initialState: IProjectLoginFormState = {
  error: undefined,
  isSubmitting: false
}

const ProjectLogin = () => {
  const { onError, setRendererState, onProjectLogin, project } = useRendererContext()
  const [formState, setFormState] = useState<IProjectLoginFormState>(initialState)

  const handleSubmit = async (values: IProjectLoginFormFields) => {
    setFormState({
      error: undefined,
      isSubmitting: true
    })

    try {
      await onProjectLogin(values)

      setFormState(prevState => ({
        ...prevState,
        isSubmitting: false
      }))

      setRendererState(prevState => ({
        ...prevState,
        isAuthenticated: true
      }))
    } catch (error) {
      setFormState({
        error,
        isSubmitting: false
      })

      setRendererState(prevState => ({
        ...prevState,
        isAuthenticated: false
      }))

      onError(error)
    }
  }

  return (
    <Wrapper>
      {project?.overview?.logo && (
        <Logo
          $position={project.overview.logo.position}
          $size={project.overview.logo.size}
        >
          <Image image={project.overview.logo.image} />
        </Logo>
      )}

      <ProjectLoginForm
        onSubmit={handleSubmit}
        isSubmitting={formState.isSubmitting}
        error={formState.error}
      />
    </Wrapper>
  )
}

export default ProjectLogin
