import Option from '@pidk/renderer/src/components/Blocks/Choice/components/Option'
import TextContent from '@pidk/renderer/src/components/Blocks/RichText/RichText'
import * as React from 'react'

import { OptionGrid } from '../common/Option'
import { TimerButton } from '../common/Timer/Timer'
import Nav from '../Nav/Nav'
import Component, { Button, Inner, Title } from '../Slide/Slide.styled'
import Area from './../Area/Area.styled'

const Styleguide = ({
  project
}) => {

  return (
    <>
      <Component>
        <TimerButton
          id='test'
          duration={100}
        />
        <Title>{project.name}</Title>
        <Inner>
          <Area
            layout={{
              halign: 'left',
              valign: 'center'
            }}
            spacing={{
              width: 6,
              height: 6
            }}
          >

            <TextContent
              align='left'
              content={[
                {
                  format: 'pretitle',
                  text: 'Pretitle'
                }, {
                  format: 'super',
                  text: 'Super'
                }, {
                  format: 'title',
                  text: 'Dit is een titel'
                }, {
                  format: 'subtitle',
                  text: 'Dit is een subtitel'
                }, {
                  format: 'lead',
                  text: 'Dit is een lead'
                }, {
                  format: 'default',
                  text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla pellentesque porta ultrices. Donec sed porttitor eros. Ut semper facilisis augue ac porttitor. '
                }, {
                  format: 'listItem',
                  text: 'listItem lorem ipsum dolor sit amet, consectetur adipiscing elit.'
                }, {
                  format: 'small',
                  text: 'Small lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla pellentesque porta ultrices. Donec sed porttitor eros. Ut semper facilisis augue ac porttitor. '
                }
              ]}
            />
            <Button>Dit is een button</Button>

          </Area>
          <Area
            layout={{
              halign: 'center',
              valign: 'center'
            }}
            spacing={{
              width: 6,
              height: 6
            }}
          >
            <OptionGrid
              $type='text'
              $layout='vertical'
            >
              <Option
                onClick={() => { }}
                isSelected={false}
                state='init'
                label='A'
              >
                <span className='label'>Dit is een optie</span>
              </Option>
              <Option
                onClick={() => { }}
                isSelected
                state='init'
                label='A'
              >
                <span className='label'>Dit is een optie</span>
              </Option>
              <Option
                onClick={() => { }}
                isSelected
                state='results'
                isCorrect={false}
                label='A'
              >
                <span className='label'>Dit is een optie</span>
              </Option>
              <Option
                onClick={() => { }}
                isSelected
                state='results'
                isCorrect
                label='A'
              >
                <span className='label'>Dit is een optie</span>
              </Option>
            </OptionGrid>
          </Area>
        </Inner>
      </Component>
      <Nav />
    </>
  )
}

Styleguide.schema = {
  defaultFieldValues: {},
  fields: []
}

export default Styleguide
