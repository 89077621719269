import type { MediaCreateBody, MediaDeleteBody, MediaFormatted, MediaRequestUploadType } from '@pidk/api/src/types/api'

import { apiRequest } from '@/utils/apiRequest'

export const requestSignedUrl = (uploadType: MediaRequestUploadType, fileName: string, projectId: string) => {
  return apiRequest('media/request-signed-url', {
    method: 'POST',
    body: {
      type: uploadType,
      fileName,
      projectId
    }
  })
}

export const createMedia = (body: MediaCreateBody): Promise<MediaFormatted> => {
  return apiRequest('media', {
    method: 'POST',
    body
  })
}

export const deleteMedia = async (body: MediaDeleteBody): Promise<void> => {
  return apiRequest('media', {
    method: 'DELETE',
    body
  })
}
