import { Button, FormFields } from '@pidk/common'
import type { FormikHelpers } from 'formik'
import { Formik, Form } from 'formik'
import * as _ from 'lodash'
import { useState } from 'react'
import styled, { css } from 'styled-components'
import * as Yup from 'yup'

import useRendererContext from '../../hooks/useRendererContext'
import { getProjectThemeColor } from '../../utils/project'

const StyledForm = styled(Form)`
  width: 100%;
  max-width: 320px;
  display: flex;
  flex-direction: column;

  ${props => css`
    background-color: ${props.theme.colors.white};
    padding: ${props.theme.spacing[8]};
    box-shadow: ${props.theme.shadows.lg};
    border-radius: ${props.theme.borderRadii.lg};
    gap: ${props.theme.spacing[4]};
  `}

  ${Button.Styled} {
    width: 100%;
    font-family: ${({ theme }) => theme.fonts.fontFamily.buttons};
  }
`

const Legend = styled.legend`
  font-weight: bold;

  ${props => css`
    color: ${({ theme }) => getProjectThemeColor(theme.colors, theme.colors.primary[500])};
    font-size: ${props.theme.fonts.fontSize['xl']};
  `}
`
const Description = styled.span`
  display: block;
  ${props => css`
    color: ${props.theme.colors.black};
    font-size: ${props.theme.fonts.fontSize['sm']};
    line-height: 1.5;
    margin-top: -${props.theme.spacing[2]};
  `}

`
const Fields = styled.div``
const Error = styled.div`
  ${props => css`
    color: ${props.theme.colors.error};
    font-size: ${props.theme.fonts.fontSize['sm']};
    margin-top: -${props.theme.spacing[4]};
  `}
`

const Footer = styled.div`
  display: flex;
  flex-direction: column;
`

export interface IProjectLoginFormFields {
  password: string
}

interface IProjectLoginForm {
  onSubmit: (values: IProjectLoginFormFields, formikHelpers: FormikHelpers<IProjectLoginFormFields>) => void | Promise<any>
  error?: unknown
  isSubmitting: boolean
}

const getErrorMessage = () => {
  return 'Probeer het nog eens!'
}

const validationSchema = Yup.object({
  password: Yup
    .string()
    .min(8, 'Het wachtwoord moet minimaal 8 tekens bevatten')
    .required('Wachtwoord is vereist')
})

const initialValues: IProjectLoginFormFields = {
  password: ''
}

const ProjectLoginForm = ({ onSubmit, error, isSubmitting }: IProjectLoginForm) => {
  const { project } = useRendererContext()
  const [state, setState] = useState(initialValues)

  const onSubmitWrapper = async (values, formikHelpers) => {
    setState(values)
    await onSubmit(values, formikHelpers)
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmitWrapper}
    >
      {({ isValid, values }) => {
        return (
          <StyledForm>
            <Legend>
              {project.overview?.login?.title || 'Inloggen'}
            </Legend>
            <Description>{project.overview?.login?.description || 'Deze inhoud is beveiligd met een wachtwoord. Voer deze hieronder in om de inhoud te bekijken.'}</Description>
            <Fields>

              <FormFields.FormField
                name='password'
                label='Wachtwoord'
              >
                <FormFields.InputText
                  name='password'
                  type='password'

                />
              </FormFields.FormField>
            </Fields>
            {error && _.isEqual(values.password, state.password) && (
              <Error>
                {getErrorMessage()}
              </Error>
            )}
            <Footer>
              <Button
                type='submit'
                variant={Button.Variant.PRIMARY}
                isLoading={isSubmitting}
                disabled={!isValid}
              >
                Doorgaan
              </Button>
            </Footer>
          </StyledForm>
        )
      }}
    </Formik>
  )
}

export default ProjectLoginForm
