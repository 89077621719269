import { nanoid } from 'nanoid'
import { useEffect } from 'react'

import useRendererContext from '../../hooks/useRendererContext'

const STORAGE_KEY = 'edg-pidk-event-session-id'

const EventSessionHandler = () => {
  const { setRendererState } = useRendererContext()

  useEffect(() => {
    let eventSessionId = localStorage.getItem(STORAGE_KEY)

    if (!eventSessionId) {
      const newSessionId = nanoid()
      localStorage.setItem(STORAGE_KEY, newSessionId)
      eventSessionId = newSessionId
    }

    setRendererState(prevState => ({
      ...prevState,
      eventSessionId
    }))
  }, [])

  return null
}

export default EventSessionHandler
