// Based on https://www.npmjs.com/package/google-fonts-helper
// Copied due to download and fs-extra package not working

import type { QueryObject } from 'ufo'
import { resolveURL, withQuery, withHttps } from 'ufo'

interface IFont {
  fonts: string[]
}

export interface FamilyStyles {
  [style: string]: boolean | number | number[]
}

export interface Families {
  [family: string]: boolean | number | number[] | FamilyStyles
}

export interface GoogleFonts {
  families?: Families
  display?: string
  subsets?: string[] | string
  text?: string
}

const GOOGLE_FONTS_DOMAIN = 'fonts.googleapis.com'

export const getGoogleFontsUrl = ({ fonts }:IFont) => {

  let uniqueFonts = [...new Set(fonts)]
  // TODO; now default loads weights 400/700 changes to dynamically add it per type
  const families = uniqueFonts.reduce((obj, cur) => ({ ...obj, [cur]: [400,700] }), {})
  return constructURL({ families: families })

}

export function isValidDisplay (display: string): boolean {
  return ['auto', 'block', 'swap', 'fallback', 'optional'].includes(display)
}

export function constructURL ({ families, display, subsets, text }: GoogleFonts = {}): string | false {
  const subset = (Array.isArray(subsets) ? subsets : [subsets]).filter(Boolean)
  const prefix = subset.length > 0 ? 'css' : 'css2'
  const family = convertFamiliesToArray(families ?? {})

  if (family.length < 1) {
    return false
  }

  const query: QueryObject = {
    family
  }

  if (display && isValidDisplay(display)) {
    query.display = display
  }

  if (subset.length > 0) {
    query.subset = subset.join(',')
  }

  if (text) {
    query.text = text
  }

  return withHttps(withQuery(resolveURL(GOOGLE_FONTS_DOMAIN, prefix), query))
}

export function convertFamiliesToArray (families: Families): string[] {
  const result: string[] = []

    Object.entries(families).forEach(([name, values]) => {
      if (!name) {
        return
      }

      if (Array.isArray(values) && values.length > 0) {
        result.push(`${name}:wght@${values.join(';')}`)
        return
      }

      if (Object.keys(values).length > 0) {
        const styles: string[] = []
        const weights: string[] = []

        Object
          .entries(values)
          .sort(([styleA], [styleB]) => styleA.localeCompare(styleB))
          .forEach(([style, weight]) => {
            styles.push(style);

            (Array.isArray(weight) ? weight : [weight]).forEach((value: string | number) => {
              if (Object.keys(values).length === 1 && style === 'wght') {
                weights.push(String(value))
              } else {
                const index = style === 'wght' ? 0 : 1
                weights.push(`${index},${value}`)
              }
            })
          })

        if (!styles.includes('wght')) {
          styles.push('wght')
        }

        const weightsSortered = weights
          .sort(([weightA], [weightB]) => weightA.localeCompare(weightB))
          .join(';')

        result.push(`${name}:${styles.join(',')}@${weightsSortered}`)
        return
      }

      if (values) {
        result.push(name)
      }
    })

  return result
}

export default getGoogleFontsUrl
