import { throttle } from 'lodash-es'

const THROTTLE_CAP = 750

export type OnProgress = (percentage: number) => void

interface IXhrWithProgress {
  url: string
  method: string
  onProgress: OnProgress
  file: File
  headers: Record<string, string>
}

const xhrWithProgress = ({
  url,
  method = 'PUT',
  onProgress,
  file,
  headers = {}
}: IXhrWithProgress): Promise<XMLHttpRequest> => new Promise((resolve, reject) => {
  const xhr = new XMLHttpRequest()

  xhr.onreadystatechange = function () {
    if (xhr.readyState === 4) {
      if (xhr.status === 200) {
        resolve(xhr)
      } else {
        reject(xhr)
      }
    }
  }

  const handleProgress = (e) => {
    if (e.lengthComputable) {
      const percentage = (e.loaded / file.size) * 100
      onProgress(percentage)
    }
  }

  if (onProgress) {
    xhr.upload.addEventListener('progress', throttle(handleProgress, THROTTLE_CAP))
  }

  xhr.onerror = (error) => {
    reject(error || new Error('An error happened with uploading'))
  }

  xhr.open(method, url)

  Object.entries(headers).forEach(e => {
    const [key, value] = e
    if (typeof value === 'string') {
      xhr.setRequestHeader(key, value)
    }
  })

  xhr.send(file)
})

export default xhrWithProgress
