import { useState, useEffect, useMemo } from 'react'

import type { ILessonState } from './../contexts/lesson'

const STORAGE_KEY = 'edustart-lesson-state'

const initialState: ILessonState = undefined

const useLessonStateProvider = () => {
  const [state, setState] = useState<ILessonState>(initialState)

  const currentSlideState = (deck: string, slide: string) => {
    return state?.[deck]?.[slide] || undefined
  }

  const storeSlideState = (deck: string, slide: string, data: unknown) => {

    const prevState = { ...state } || {}

    prevState[deck] = { ...prevState[deck] } || {}
    prevState[deck][slide] = data

    setState(prevState)

  }

  const clearState = () => {
    setState(undefined)
  }

  // On load retrieve state from session storage
  useEffect(() => {
    if (sessionStorage[STORAGE_KEY]) {
      setState(JSON.parse(window.sessionStorage[STORAGE_KEY]))
    }
  }, [])

  // Store context in Session
  useMemo(() => {

    if (state && Object.keys(state).length > 0) {
      sessionStorage[STORAGE_KEY] = JSON.stringify(state)
    }

  }, [state])

  return {
    state,
    currentSlideState,
    storeSlideState,
    clearState
  }
}

export default useLessonStateProvider
