// import { EventType } from '@pidk/api/src/types/api'
import type { Project } from '@pidk/compose/src/types/compose'
// import Image from '@pidk/renderer/src/components/Blocks/Image'
// import { getCurrentDeck } from '@pidk/renderer/src/utils/project'
// import { ArrowRightIcon } from '@pidk/common/src/components/Icons'

// import useRendererContext from '../../hooks/useRendererContext'
// import { RendererView } from '../../types/slide'
// import TextContent from '../Blocks/RichText'
// import Area from './../Area/Area.styled'
// import ButtonPopover from './../common/ButtonPopover'
// import { Inner } from './../Slide/Slide.styled'
// import {
//   Component,
//   Grid,
//   GridItem,
//   GridItemButton,
//   Logo,
//   Heading
// } from './Overview.styled'

import { getCurrentSlide } from '../../utils/project'
import { getSlideTypeComponent } from '../../utils/slide'

interface IOverviewProps {
  project: Project
}

const Overview = ({
  project
}: IOverviewProps) => {
  // const { onScopedEventCreate } = useRendererContext()
  // const [showStartModal, setShowStartModal] = useState<string | null>(null)
  const { overview } = project
  // const [bgImage] = useProjectMedia([overview?.properties?.background?.image])

  // const backgroundProperties = {
  //   ...overview?.properties?.background,
  //   image: bgImage?.url
  // }

  // const handleBtnDeckClick = (deckId: string) => () => {
  //   setShowStartModal(deckId)
  // }

  // const handleHideModalStart = () => {
  //   setShowStartModal(null)
  // }

  // const handleOpenDownload = async (i) => {
  //   await onScopedEventCreate({
  //     type: EventType.PROJECT_DOWNLOAD_FILE,
  //     meta: {
  //       fileName: i.label
  //     }
  //   })

  //   window.open(i.url)
  // }

  if (!overview?.screen) {
    return null
  }

  const overviewScreen = overview.screen
  const currentSlide = getCurrentSlide(project, overviewScreen.deck, overviewScreen.slide)

  if (!currentSlide) {
    return null
  }

  const SlideComponent = getSlideTypeComponent(currentSlide?.type)

  return (
    <SlideComponent
      slide={currentSlide}
      logo={overview?.logo}
      full
    />
  )
}

export default Overview
