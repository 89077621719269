// import type { RoomFormatted } from '@pidk/api/src/types/api'
import type { Dispatch, SetStateAction } from 'react'
import { useState } from 'react'
import type { Socket } from 'socket.io-client'

export interface ISocketContext {
  state: ISocketState // TODO: change any to room interface
  setState: Dispatch<SetStateAction<ISocketState>>
}

interface ISocketState {
  socket?: Socket
  room?: any
  isConnected: boolean
  isInitialized: boolean
}

const initialState: ISocketState = {
  socket: undefined,
  room: undefined,
  isConnected: false,
  isInitialized: false
}

const useSocketContextProvider = () => {
  const [state, setState] = useState<ISocketState>(initialState)

  return {
    state,
    setState
  }
}

export default useSocketContextProvider
