import getGoogleFontsUrl from '@pidk/common/src/utils/fonts'
import type { Project } from '@pidk/compose/src/types/compose'
import { useMemo } from 'react'

const useProjectFonts = (project: Project) => {
  return useMemo(() => {
    const fonts = project?.theme?.fonts?.fontFamily

    if (!fonts) {
      return null
    }

    const url = getGoogleFontsUrl({ fonts: [fonts?.heading, fonts?.body, fonts?.buttons] })

    if (!url) {
      return null
    }

    return url
  }, [project?.theme?.fonts])
}

export default useProjectFonts
