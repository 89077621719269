import { getProjectThemeColor } from '@pidk/renderer/src/utils/project'
import { rgba } from 'polished'
import styled from 'styled-components'

const Component = styled.div`
    position: relative;
    height: 10px;
    width: 100%;
    background: ${props => rgba(getProjectThemeColor(props.theme.colors, props.theme.nav.actionBackgroundColor), 0.2)};
`

const Progress = styled.span<IProgressbar>`
   position: absolute;
   top: 0;
   bottom: 0;
   left: 0;
   height: 100%;
   width: ${({ current, total }) => ((current / total) * 100) + '%'};
   background: ${props => getProjectThemeColor(props.theme.colors, props.theme.nav.actionBackgroundColor)};
   transition: width 100ms linear;
`
interface IProgressbar {
  current: number
  total: number
}

export const Progressbar = ({ current, total }: IProgressbar) => {
  return (
    <Component>
      <Progress
        current={(current === 0 ? 0 : current + 1)}
        total={total}
      />
    </Component>
  )
}

Progressbar.Styled = Component

export default Progressbar
