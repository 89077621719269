import { RoomEvents } from '@pidk/api/src/types/api'
import { io } from 'socket.io-client'
import type { Socket } from 'socket.io-client'

import type { OnReconnectPlayer } from '../components/Player/Player'
import type { OnRoomCreate } from '../contexts/renderer'
import useSocket from './useSocket'

const connectSocket = (socket: Socket): Promise<void> => new Promise((resolve, reject) => {
  socket.on('connect', resolve)
  socket.on('connect_error', reject)
  socket.connect()
})

export interface IUseSocketInitialize {
  onError: (error: any) => void
  socketUrl: string
  projectId?: string
  onReconnect?: OnRoomCreate | OnReconnectPlayer
  isHost?: boolean
}

const useSocketInitialize = ({ onError, socketUrl, onReconnect, projectId, isHost }: IUseSocketInitialize) => {
  const socketContext = useSocket()

  return async () => {
    if (!socketUrl) {
      throw new Error(`No socketUrl specified, ${socketUrl}`)
    }

    try {
      const socket = socketContext.state.socket || io(socketUrl, { autoConnect: false, closeOnBeforeunload: false })

      // Setup event listeners
      socket.on('connect', () => {
        socketContext.setState(prevState => ({
          ...prevState,
          isConnected: true
        }))
      })

      socket.on('disconnect', () => {
        socketContext.setState(prevState => ({
          ...prevState,
          isConnected: false
        }))
      })

      if (isHost) {
        socket.on(RoomEvents.ROOM_UPDATED, (room) => {
          socketContext.setState(prevState => ({
            ...prevState,
            room
          }))
        })
      }

      if (!isHost) {
        socket.on(RoomEvents.ROOM_PARTICIPANT_UPDATED, (room) => {
          socketContext.setState(prevState => ({
            ...prevState,
            room
          }))
        })

        socket.on(RoomEvents.LAST_INTERACTION_UPDATED, (lastInteraction) => {
          socketContext.setState(prevState => ({
            ...prevState,
            room: {
              ...prevState.room,
              hasAnswered: false,
              lastInteraction
            }
          }))
        })
      }

      // Connect
      if (!socket.connected) {
        await connectSocket(socket)
      }

      if (process.env.NODE_ENV === 'development') {
        global.socket = socket
      }

      socket?.io.on('close', async () => {
        socket.once('connect', async () => {
          const participantId = sessionStorage.getItem('participantId')
          const code = sessionStorage.getItem('code')

          if (!code) {
            return
          }

          if (!participantId) {
            await (onReconnect as OnRoomCreate)(socket.id, projectId, code)
            sessionStorage.setItem('code', code)
            return
          }

          setTimeout(async () => {
            await (onReconnect as OnReconnectPlayer)(code, socket.id, participantId)
          }, 3000)
        })
      })

      socketContext.setState(prevState => ({
        ...prevState,
        socket
      }))

      return socket.id
    } catch (e) {
      onError(e)
    }
  }
}

export default useSocketInitialize
