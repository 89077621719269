import { EventType } from '@pidk/api/src/types/api'
import { ArrowLeftIcon, ArrowRightIcon, HomeIcon } from '@pidk/common/src/components/Icons'
import type { DeckParsed } from '@pidk/compose/src/types/compose'
import {
  getDeckHasStartedFromStorage,
  removeDeckStartedFromStorage,
  setDeckStartedInStorage
} from '@pidk/renderer/src/utils/event'
import { darken } from 'polished'
import styled from 'styled-components'

import useRendererContext from '../../hooks/useRendererContext'
import useSlidePosition from '../../hooks/useSlidePosition'
import useSocket from '../../hooks/useSocket'
import { RendererView } from '../../types/slide'
import { getCurrentDeck, getProjectThemeColor } from '../../utils/project'
import { Progressbar } from '../common/Progressbar'
import RoomControls from './RoomControls'

// TODO: cleanup navbar elements with roomControls

const Component = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 7vh;
  background-color: ${({ theme }) => getProjectThemeColor(theme.colors, theme.nav.backgroundColor)};
  color: ${({ theme }) => getProjectThemeColor(theme.colors, theme.nav.color)};

  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: ${props => props.theme.fonts.fontFamily.body};

  ${Progressbar.Styled} {
    width: 15%;
    margin-right: 24px;
    overflow: hidden;
    border-radius: 8px;
    height: 8px;
  }
`

const MainNavigation = styled.div<{ disabled: boolean }>`
  display: flex;
  height: 100%;
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
`

const NavBtn = styled.button`
  height: 100%;
  padding: 0 1rem;
  background: none;
  border: 0;
  min-width: 7vh;
  cursor: pointer;
  font-weight: bold;
  font-family: ${({ theme }) => theme.fonts.fontFamily.buttons};
  font-size: ${({ theme }) => theme.fonts.fontSize['lg']};
  color: ${({ theme }) => getProjectThemeColor(theme.colors, theme.nav.color)};

  svg {
    width: 4vh;
    height: 4vh;
  }

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    background: ${({ theme }) => darken(0.05, getProjectThemeColor(theme.colors, theme.nav.backgroundColor))}
  }
  &:last-child {
    margin-right: 0;
  }

  &:disabled {
    opacity: 0.2;
    cursor: not-allowed;
  }
`

const getHasVisibleSlidesUpcoming = (currentDeck: DeckParsed, currentIndex: number) => {
  let hasVisibleSlidesUpcoming = true

  for (let i = currentIndex + 1; i < currentDeck?.slides.length; i++) {
    const nextSlide = currentDeck?.slides[i]

    if (!nextSlide?.data?.hidden) {
      hasVisibleSlidesUpcoming = true
      break
    }

    hasVisibleSlidesUpcoming = false
  }

  return hasVisibleSlidesUpcoming
}

const Nav = () => {
  const {
    onNavigate,
    project,
    currentDeckId,
    isEditable,
    rendererState,
    setRendererState,
    onScopedEventCreate,
    onRoomClearLastInteraction
  } = useRendererContext()
  const socketContext = useSocket()
  const currentDeck = getCurrentDeck(project, currentDeckId) as any
  const { hasNext, hasPrev, currentIndex } = useSlidePosition()

  const handlePrevClick = async () => {
    if (rendererState.hasStarted && socketContext.state.room?.lastInteraction) {
      await onRoomClearLastInteraction(socketContext.state.socket?.id, socketContext.state.room?.code)
    }

    for (let i = currentIndex - 1; i >= 0; i--) {
      const prevSlide = currentDeck?.slides[i]

      if (!prevSlide?.data?.hidden) {
        onNavigate(RendererView.DECK, currentDeckId, prevSlide.id)

        return
      }
    }
  }

  const handleNextClick = async () => {
    if (rendererState.hasStarted && socketContext.state.room?.lastInteraction) {
      await onRoomClearLastInteraction(socketContext.state.socket?.id, socketContext.state.room?.code)
    }

    for (let i = currentIndex + 1; i < currentDeck?.slides.length; i++) {
      const nextSlide = currentDeck?.slides[i]

      if (!nextSlide?.data?.hidden) {
        onNavigate(RendererView.DECK, currentDeckId, nextSlide.id)

        return
      }
    }
  }

  const handleHomeClick = async () => {
    const hasHomeLink = currentDeck?.homeLink?.deck && currentDeck?.homeLink?.slide
    const isSameDeck = currentDeck?.homeLink?.deck === currentDeckId

    if (isSameDeck && hasHomeLink) {
      onNavigate(RendererView.DECK, currentDeck.homeLink.deck, currentDeck?.homeLink?.slide)
      return
    }

    if (!isSameDeck) {
      const startedDeck = getDeckHasStartedFromStorage(currentDeck.id)

      if (rendererState.hasStarted && socketContext.state.room?.lastInteraction) {
        await onRoomClearLastInteraction(socketContext.state.socket?.id, socketContext.state.room?.code)
      }

      if (rendererState.hasStarted) {
        if (!window.confirm('De les is actief, weet u zeker dat u deze wilt afsluiten?')) {
          return
        }

        setRendererState(prevState => ({
          ...prevState,
          hasStarted: false
        }))

        removeDeckStartedFromStorage()

        await onScopedEventCreate({
          type: EventType.DECK_CLOSE,
          deckId: startedDeck.deckId
        })
      }

      if (hasHomeLink) {
        onNavigate(RendererView.DECK, currentDeck.homeLink.deck, currentDeck?.homeLink?.slide)
        return
      }

      onNavigate(RendererView.OVERVIEW, null, null)
    }
  }

  const handleLessonStartClick = async () => {
    const deck = getCurrentDeck(project, currentDeckId) as any
    const slideId = project.decks.find(d => d.id === deck.id)?.slides?.[0]?.id

    setDeckStartedInStorage(deck.id)

    await onScopedEventCreate({
      type: EventType.DECK_START,
      deckId: deck.id
    })

    setRendererState(prevState => ({
      ...prevState,
      hasStarted: true,
      hasAskedForStartLesson: true
    }))

    onNavigate(RendererView.DECK, deck.id, slideId)
  }

  const amountVisibleSlides = currentDeck?.slides?.filter(slide => !slide.data?.hidden)?.length
  const hasVisibleSlidesUpcoming = getHasVisibleSlidesUpcoming(currentDeck, currentIndex)

  return (
    <Component>
      <MainNavigation disabled={isEditable}>
        {project.overview?.screen?.deck !== currentDeckId && (
          <NavBtn
            onClick={handleHomeClick}
          >
            <HomeIcon />
          </NavBtn>
        )}
        {(hasNext || hasPrev) && (
          <>
            <NavBtn
              disabled={!hasPrev}
              onClick={handlePrevClick}
            >
              <ArrowLeftIcon />
            </NavBtn>
            <NavBtn
              disabled={!hasNext || !hasVisibleSlidesUpcoming}
              onClick={handleNextClick}
            >
              <ArrowRightIcon />
            </NavBtn>
          </>
        )}
        {rendererState.hasAskedForStartLesson && !rendererState.hasStarted && (
          <NavBtn
            onClick={handleLessonStartClick}
          >
            Les starten
          </NavBtn>
        )}
        {currentDeck.hasVoting && rendererState.hasAskedForStartLesson && rendererState.hasStarted && (
          <RoomControls
            hasVoting={currentDeck.hasVoting}
          />
        )}
      </MainNavigation>

      {currentDeck.showProgressbar && (
        <Progressbar
          current={currentIndex}
          total={amountVisibleSlides}
        />
      )}
    </Component>
  )
}

export default Nav
