import { EventType } from '@pidk/api/src/types/api'
// import { Button } from '@pidk/common'
import { Modal } from '@pidk/common/src/components/Modal'
import { RendererView } from '@pidk/renderer/src/types/slide'
import { setDeckStartedInStorage } from '@pidk/renderer/src/utils/event'
import { getCurrentDeck } from '@pidk/renderer/src/utils/project'
import { darken } from 'polished'
import { useState } from 'react'
import styled, { css } from 'styled-components'

import useRendererContext from '../../hooks/useRendererContext'
import useSocket from '../../hooks/useSocket'
import { Button } from '../Slide/Slide.styled'

// TODO Cleanup with generic styling for content and buttons

const Title = styled.h2`
  font-family: ${props => props.theme.fonts.fontFamily.heading};
`

const Description = styled.div`
  font-family: ${props => props.theme.fonts.fontFamily.body};
  color: ${props => props.theme.colors.black};
  font-size: 1.25rem;
  line-height: 1.2;
  margin: .5rem 0;

  small {
    display: block;
    font-size: 1rem;
    color: ${props => props.theme.colors.gray};
    margin-top: 1rem;
  }
`

const Footer = styled.div`
  display: flex;
  margin-top: 2rem;
  gap: 2rem;

  > * {
    flex: 1 1 auto;
    width: 100%;
  }
`

const ModalButton = styled(Button) <any>`
  height: 3.75rem;
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.white};
  font-size: 1.25rem;
  justify-content: center;

  &:focus,
  &:hover {
    background-color: ${props => darken(0.05, props.theme.colors.primary)};
    color: ${props => props.theme.colors.white};
  }

  &:focus {
    outline: 2px solid ${props => darken(0.05, props.theme.colors.primary)};
    outline-offset: 2px;
  }

  ${({ $variant, theme }) => {
    if ($variant === 'secondary') {
      return css`
          border: 2px solid ${theme.colors.primary};
          background-color: transparent;
          color: ${theme.colors.primary};

          &:focus,
          &:hover {
            background-color: ${theme.colors.light};
            color: ${theme.colors.primary};
          }
        `
    }
  }}
`

interface IModalStart {
  onHide: () => void
  deckId: string
}

const ModalStart = ({
  onHide,
  deckId
}: IModalStart) => {
  const { project, onNavigate, setRendererState, onScopedEventCreate, isEditable } = useRendererContext()
  const [isStarting, setIsStarting] = useState<boolean>(false)
  const socketContext = useSocket()

  const handleModalStartOnPreview = async () => {
    const deck = getCurrentDeck(project, deckId) as any
    const slideId = project.decks.find(d => d.id === deck.id)?.slides?.[0]?.id

    await onScopedEventCreate({
      type: EventType.DECK_PREVIEW,
      deckId: deck?.id
    })

    onHide()

    setRendererState(prevState => ({
      ...prevState,
      hasStarted: false,
      hasAskedForStartLesson: true
    }))

    onNavigate(RendererView.DECK, deck.id, slideId)
  }

  const handleModalStartOnStart = async () => {
    const deck = getCurrentDeck(project, deckId) as any
    const slideId = project.decks.find(d => d.id === deck.id)?.slides?.[0]?.id

    setIsStarting(true)

    setDeckStartedInStorage(deck.id)

    await onScopedEventCreate({
      type: EventType.DECK_START,
      deckId: deck?.id
    })

    setIsStarting(false)

    setRendererState(prevState => ({
      ...prevState,
      hasStarted: true,
      hasAskedForStartLesson: true
    }))

    onHide()

    onNavigate(RendererView.DECK, deck.id, slideId)
  }

  if (isEditable) {
    return null
  }

  if (!socketContext.state.isInitialized) {
    return null
  }

  return (
    <Modal
      type='dialog'
      onClose={handleModalStartOnPreview}
    >
      <Title>
        Aan de slag
      </Title>
      <Description>
        Wil je de les voorbereiden? Of ga je direct aan de slag voor de klas? Druk dan op <strong>les starten.</strong>
        <small>Gebruik altijd de F11-toets om de les op volledig scherm te zien!</small>
      </Description>
      <Footer>
        <ModalButton
          as='button'
          $variant='secondary'
          onClick={handleModalStartOnPreview}
        >
          Voorbereiden
        </ModalButton>
        <ModalButton
          as='button'
          onClick={handleModalStartOnStart}
          isLoading={isStarting}
        >
          Starten
        </ModalButton>
      </Footer>
    </Modal>
  )
}

export default ModalStart
