import generateClamp from '@pidk/common/src/utils/clamp'
import * as React from 'react'
import styled, { ThemeProvider } from 'styled-components'

import RendererContext from '../../contexts/renderer'
import SocketContext from '../../contexts/socket'
import type { IRendererContextProps } from '../../hooks/useRendererContextProvider'
import useRendererContextProvider from '../../hooks/useRendererContextProvider'
import useSocketContextProvider from '../../hooks/useSocketContextProvider'
import { RendererView } from '../../types/slide'
import { getProjectThemeColor, getProjectWithDefaultTheme } from '../../utils/project'
import EventSessionHandler from '../EventSessionHandler/EventSessionHandler'
import Overview from '../Overview/Overview'
import ProjectLogin from '../ProjectLogin/ProjectLogin'
import Slide from '../Slide/Slide'
import SocketHandler from '../SocketHandler/SocketHandler'
import Styleguide from '../Styleguide/Styleguide'
import LessonStateContext from './../../contexts/lesson'
import useLessonStateProvider from './../../hooks/useLessonStateProvider'

const Component = styled.div`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  /* /* font-size: 2vw; */
  //font-size: ${generateClamp(16, 24, 320, 1920)}
  background-color: ${({ theme }) => getProjectThemeColor(theme.colors, theme.screen.backgroundColor)};
  color: ${({ theme }) => getProjectThemeColor(theme.colors, theme.screen.color)};
`

// TODO: remove me, temporary fix since React 18 upgrade
const FixedThemeProvider = ThemeProvider as any

export type RendererProps = IRendererContextProps & {
  view: RendererView
}

const Renderer = ({
  isPreview,
  isAuthenticated,
  project,
  currentDeckId,
  currentSlideId,
  view,
  isEditable,
  onAreaClick,
  selectedAreaId,
  onNavigate,
  onError,
  onRoomCreate,
  onProjectLogin,
  onRoomCreateInteraction,
  onRoomGetInteractionResults,
  onRoomDeactivate,
  onRoomActivate,
  onRoomClearLastInteraction,
  onEventCreate,
  socketUrl
}: RendererProps) => {
  const lessonStateContext = useLessonStateProvider()
  const socketContext = useSocketContextProvider()
  const projectWithDefaultTheme = getProjectWithDefaultTheme(project)

  const rendererContext = useRendererContextProvider({
    isPreview,
    isAuthenticated,
    onNavigate,
    project: projectWithDefaultTheme,
    currentDeckId,
    currentSlideId,
    isEditable,
    onAreaClick,
    selectedAreaId,
    onError,
    socketUrl,
    onProjectLogin,
    onRoomCreate,
    onRoomCreateInteraction,
    onRoomGetInteractionResults,
    onRoomDeactivate,
    onRoomActivate,
    onRoomClearLastInteraction,
    onEventCreate
  })

  return (
    <RendererContext.Provider value={rendererContext}>
      <SocketContext.Provider value={socketContext}>
        <LessonStateContext.Provider value={lessonStateContext}>
          <FixedThemeProvider theme={projectWithDefaultTheme.theme}>
            <Component>
              <EventSessionHandler />

              {/* TODO: Maybe put SocketHandler in the else statement so it only connects when someone actually logged in */}
              <SocketHandler />
              {!rendererContext.rendererState.isAuthenticated && !isEditable ? (
                <ProjectLogin />
              ) : (
                <>
                  {view === RendererView.DECK && (
                    <Slide
                      project={project}
                      currentDeckId={currentDeckId}
                      currentSlideId={currentSlideId}
                      isEditable={isEditable}
                      onAreaClick={onAreaClick}
                      selectedAreaId={selectedAreaId}
                    />
                  )}
                  {view === RendererView.OVERVIEW && (
                    <Overview
                      project={project}
                    />
                  )}
                  {view === RendererView.THEME && (
                    <Styleguide
                      project={project}
                    />
                  )}
                </>
              )}
            </Component>
          </FixedThemeProvider>
        </LessonStateContext.Provider>
      </SocketContext.Provider>
    </RendererContext.Provider>
  )
}

export default Renderer
