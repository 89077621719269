const STORAGE_KEY = 'edg-pidk-deck-events'

interface IEventObject {
  deckId: string
  meta?: {}
}

export const setDeckStartedInStorage = (deckId: string, meta?: {}) => {
  const eventObject: IEventObject = {
    deckId,
    meta: {
      ...meta
    }
  }

  sessionStorage.setItem(STORAGE_KEY, JSON.stringify(eventObject))
}

export const getDeckStartedFromStorage = (): IEventObject => {
  const eventObject = sessionStorage.getItem(STORAGE_KEY)

  return JSON.parse(eventObject)
}

export const getDeckHasStartedFromStorage = (deckId: string): IEventObject | null => {
  const eventObject = getDeckStartedFromStorage()

  if (eventObject?.deckId === deckId) {
    return eventObject
  }

  return null
}

export const removeDeckStartedFromStorage = () => {
  sessionStorage.removeItem(STORAGE_KEY)
}
