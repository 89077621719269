import { MediaRequestUploadType } from '@pidk/api/src/types/api'
import type { MediaFormatted } from '@pidk/api/src/types/api'
import type { OnRemoveProjectMedia, OnUploadProjectMedia } from '@pidk/compose/src/contexts/compose'
import * as Sentry from '@sentry/nextjs'
import pLimit from 'p-limit'

import { createMedia, deleteMedia, requestSignedUrl } from '@/api/media'
import { addProjectMedia } from '@/api/projects'
import type { OnProgress } from '@/utils/xhrWithProgress'
import xhrWithProgress from '@/utils/xhrWithProgress'

const UPLOAD_CONCURRENCY = 4

interface IUploadProjectMediaResult {
  bucketName: string
  bucketFolder: string
  uniqueFileName: string
  fileBucketLocation: string
  signedUrl: string
}

export const uploadProjectMedia = async (
  uploadType: MediaRequestUploadType,
  file: File,
  projectId: string,
  onProgress?: OnProgress,
  hasCache: boolean = true
): Promise<IUploadProjectMediaResult> => {
  const res = await requestSignedUrl(uploadType, file.name, projectId)
  const signedUrl = res.signedUrl

  if (!signedUrl) {
    throw new Error('Unknown')
  }

  const oneYearFromNow = new Date()
  oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1)

  let headers: {} = {
    'cache-control': 'max-age=31536000',
    Expires: oneYearFromNow
  }

  if (!hasCache) {
    headers = {}
  }

  await xhrWithProgress({
    url: signedUrl,
    method: 'PUT',
    onProgress,
    file,
    headers
  })

  return res
}

export const uploadProjectMediaHandler: OnUploadProjectMedia = async (projectId, files, onProgress): Promise<MediaFormatted[]> => {
  const limit = pLimit(UPLOAD_CONCURRENCY)

  try {
    const jobs: Promise<MediaFormatted>[] = files.map(file => {
      return limit(async () => {
        const uploadRes = await uploadProjectMedia(
          MediaRequestUploadType.PROJECT_MEDIA,
          file,
          projectId,
          progress => onProgress(file.name, progress)
        )

        const newMedia = await createMedia({
          bucketName: uploadRes.bucketName,
          bucketPath: uploadRes.bucketFolder,
          fileBucketLocation: uploadRes.fileBucketLocation,
          name: file.name,
          mimeType: file.type
        })

        await addProjectMedia(projectId, [newMedia.id])
        return newMedia
      })
    })

    return await Promise.all(jobs)
  } catch (error) {
    Sentry.captureException(error)
    throw error
  }
}

export const removeProjectMediaHandler: OnRemoveProjectMedia = async (projectId: string, mediaIds: string[]): Promise<void> => {
  try {
    await deleteMedia({
      mediaIds
    })
  } catch (error) {
    Sentry.captureException(error)
    throw error
  }
}

export const cacheMedia = async (media) => {
  // Only cache media with mimeType image
  const promises = await media.filter(m => m.mimeType?.includes('image')).map((image) => {
    return new Promise(function (resolve, reject) {
      const img = new Image()
      img.src = image.url
      img.onload = resolve
      img.onerror = reject
    })
  })

  return await Promise.all(promises)
}
